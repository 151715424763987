function generatePojoClass(json, useFinal = false) {
    json = cleanCommas(json)
    return parseObj(json, "Root", useFinal);
}


function parseObj(json, className, useFinal = false) {
    json = cleanCommas(json)

    let parsed = JSON.parse(json);

    if (Array.isArray(parsed)) {
        parsed = parsed[0];
    }

    //todo remove 's at the end if List
    //todo try to fix arrays in json like map them to same type
    let keys = Object.keys(parsed);
    let classBody = "";

    let additionalClasses = "";
    keys.forEach((key) => {
        let value = parsed[key];
        let strValue = getStrValue(json, key);
        let jsonType = getJsonType(value, strValue);
        let javaType = getJavaType(jsonType, key, value, strValue);

        if (jsonType === 'Object' && value !== null) {
            let innerClass = parseObj(JSON.stringify(value), javaType, useFinal);
            additionalClasses += innerClass;
        }
        if (jsonType === 'Array' && additionalClassRequired(extractGenericType(javaType))) {
            let clazzName = toClazzName(extractGenericType(javaType));
            let innerClass = parseObj(JSON.stringify(value[0]), clazzName, useFinal);
            additionalClasses += innerClass;
        }

        let comment = "";
        if (value === null) {
            comment = `//todo can't determine type of null value - check it manually`;
        }

        let final = useFinal ? 'final ' : '';
        classBody += `    private ${final}${javaType} ${camelCase(key)};${comment}\n`;
    });

    let mainClass = `class ${className} {\n${classBody}}`;

    return mainClass + "\n" + additionalClasses;
}

function getJsonType(value, strValue) {
    if (typeof value === 'string') {
        return 'String';
    } else if (Array.isArray(value)) {
        return 'Array';
    } else if (typeof value === 'number') {
        if (Number.isInteger(value) && !strValue.includes('.')) {
            return 'Long';
        } else {
            return 'Double';
        }
    } else if (typeof value === 'boolean') {
        return 'Boolean';
    } else if (typeof value === 'object') {
        return 'Object';
    }
}

function getJavaType(jsonType, key, value, strValue="") {
    switch (jsonType) {
        case 'String':
            return 'String';
        case 'Long':
            return 'Long';
        case 'Double':
            return 'Double';
        case 'Boolean':
            return 'Boolean';
        case 'Object':
            if (value === null) {
                return 'Object';
            }
            return toClazzName(key);
        case 'Array':
            if (value.length === 0) {
                return 'List<Object>';
            } else {
                let arrayJsonTypes = new Set(value.map(v => getJsonType(v, strValue)));


                let arrayJavaType = arrayJsonTypes.has("Array") ? getJavaType("Array", key, value[0]) :
                    arrayJsonTypes.has("Object") ? toClazzName(key, true) :
                        arrayJsonTypes.has("String") ? "String" :
                            arrayJsonTypes.has("Double") ? "Double" :
                                arrayJsonTypes.has("Long") ? "Long" :
                                    "Boolean";

                return `List<${arrayJavaType}>`;
            }
    }
}

function additionalClassRequired(value) {
    return !['String', 'Long', 'Double', 'Boolean', 'Object', 'List'].some(v => value.startsWith(v));
}

function extractGenericType(str) {
    const match = str.match(/<(.*)>/);
    return match ? match[1] : null;
}

function toClazzName(str, array = false) {
    let key = camelCase(str)
    if (array && key.charAt(key.length - 1) === 's') {
        key = key.slice(0, -1);
    }
    return key.charAt(0).toUpperCase() + key.slice(1);
}

function camelCase(str) {
    str = str.replace("-", " ")
    str = str.replace("_", " ")
    return str
        .replace(/\s(.)/g, function (a) {
            return a.toUpperCase();
        })
        .replace(/\s/g, '')
        .replace(/^(.)/, function (b) {
            return b.toLowerCase();
        });
}

function cleanCommas(json) {
    let resp = json.charAt(json.length - 1) === ',' ? json.slice(0, -1) : json;
    return resp.charAt(0) === ',' ? resp = resp.slice(1) : resp;
}

function getStrValue(json, field) {
    let regex = new RegExp(`"${field}"\\s*:\\s*([0-9\\.]+)`);
    let match = json.match(regex);
    return match ? match[1] : "";
}

export default generatePojoClass;
