import { useState } from "react";

import Editor from "./Editor";
import generatePojoClass from "./PojoGenerator";
import { Checkbox, FormControlLabel, IconButton, Tooltip } from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';


function App() {
    const [open, setOpen] = useState(false);
    const [useFinal, setUseFinal] = useState(false);
    let initialState = "{\n" +
        "   \"field\": \"value\"\n" +
        "}";
    const [jsonInput, setJsonInput] = useState(initialState);
    const [pojoOutput, setPojoOutput] = useState(generatePojoClass(initialState, useFinal));

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };

    const handleCheckboxChange = (event) => {
        setUseFinal(event.target.checked);
        let pojo = generatePojoClass(jsonInput, !useFinal);//! йобаний костиль нахуй
        setPojoOutput(pojo);
    };

    const handleJsonInputChange = (newJsonInput) => {
        setJsonInput(newJsonInput);

        try {
            const pojo = generatePojoClass(newJsonInput, useFinal);
            setPojoOutput(pojo);
        } catch (error) {
            console.log(error)
            setPojoOutput('Invalid JSON');
        }
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(pojoOutput);
        handleTooltipOpen();
        setTimeout(handleTooltipClose, 2000);
    };

    return (
        <div className="App">
            <header className="content_wrapper">
                <h1>JSON to Java</h1>
                <p className="wo_subheader">Convert any JSON object to a POJO JAVA class online. Check below panel on how to use this converter.</p>
            </header>
            <div className="Content">
                <div className="Editor">
                    <div className="JavaMenu">
                        <h2>JSON:</h2>
                    </div>
                    <Editor
                        mode="json"
                        value={jsonInput}
                        onChange={handleJsonInputChange}
                    />
                </div>
                <div className="Editor">
                    <div className="JavaMenu">
                        <h2>Java:</h2>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={useFinal}
                                    onChange={handleCheckboxChange}
                                />
                            }
                            label="final" />
                        <Tooltip open={open} onClose={handleTooltipClose} title="Copied :)">
                            <IconButton aria-label="delete" onClick={copyToClipboard}>
                                <ContentCopyIcon sx={{ color: '#f5f5f5' }} />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <Editor
                        mode="java"
                        value={pojoOutput}
                    />
                </div>
            </div>
        </div>
    );
}

export default App;
