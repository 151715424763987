import React from 'react';
import AceEditor from 'react-ace';

import 'brace/mode/json';
import 'brace/mode/java';
import 'brace/theme/monokai';

function Editor({ mode, value, onChange, width = "42vw", height = "70vh" }) {
    return (
        <AceEditor
            mode={mode}
            theme="monokai"
            value={value}
            fontSize={14}
            showPrintMargin={true}
            showGutter={true}
            highlightActiveLine={true}
            onChange={onChange}
            name={`UNIQUE_ID_OF_DIV_${mode}`}
            editorProps={{ $blockScrolling: true }}
            setOptions={{
                enableBasicAutocompletion: false,
                enableLiveAutocompletion: false,
                enableSnippets: false,
                showLineNumbers: true,
                highlightActiveLine: true,
                enableMultiselect: true,
                tabSize: 2,
            }}
            width={width}
            height={height}
        />
    );
}

export default Editor;